<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Confirmation Dates</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <date-picker label="Confirmation Open Date" v-model="start" :min-date="confirmationWindow" :max-date="termStart"></date-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <date-picker label="Confirmation Close Date" v-model="end" :min-date="termStart" :max-date="termEnd"></date-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <date-picker label="Late Fee Date" v-model="late" :min-date="termStart" :max-date="termEnd"></date-picker>
        </v-col>
      </v-row>
    </v-card-text>
    <v-toolbar flat>
      <v-toolbar-title>Checklist Items</v-toolbar-title>
    </v-toolbar>
    <v-list v-if="checklistItems.length > 0">
      <checklist-dialog v-for="(item, index) in checklistItems" :key="item._id" :checklist-item="item" :item-index="index" :checklist="checklistItems" @update="(item) => updateItem(item, index)"></checklist-dialog>
    </v-list>
    <v-card-text v-else>
      <p>There are no checklist items set up for this term. Would you like to copy them from a previous term?</p>
      <v-select v-model="copySourceTerm" :items="termsList" item-text="label" item-value="_id" label="Copy From Term"></v-select>
      <v-list v-if="copyItems.length > 0">
        <v-list-item-group v-model="selectedItems" multiple>
          <v-list-item v-for="(item, index) in copyItems" :key="index">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.detail }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="item.active ? 'success' : 'error'">{{ item.active ? 'fas fa-check' : 'fal fa-times' }}</v-icon>
                  </template>
                  <span>{{ item.active ? 'Checklist item was active' : 'Checklist item was not active' }}</span>
                </v-tooltip>
              </v-list-item-avatar>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn :disabled="copySourceTerm === '' || selectedItems.length === 0" @click="copyFromTerm">Copy Checklist</v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  props: {
    termStart: {
      type: String,
      required: true
    },
    termEnd: {
      type: String,
      required: true
    },
    termId: {
      type: String,
      required: true
    }
  },
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker'),
    ChecklistDialog: () => import('./checklist-dialog.vue')
  },
  setup (props, { root, emit }) {
    const start = ref('')
    const late = ref('')
    const end = ref('')
    watch([start, late, end], async () => {
      if (props.termId != null && props.termId !== '') {
        let obj = {}
        let updated = false
        const { confirmation } = await root.$feathers.service('system/term').get(props.termId)
        if (start.value != null && start.value !== '' && start.value !== confirmation.start.substr(0, 10)) {
          obj['confirmation.start'] = start.value
          updated = true
        }
        if (late.value != null && late.value !== '' && late.value !== confirmation.late.substr(0, 10)) {
          obj['confirmation.late'] = late.value
          updated = true
        }
        if (end.value != null && end.value !== '' && end.value !== confirmation.end.substr(0, 10)) {
          obj['confirmation.end'] = end.value
          updated = true
        }
        if (updated) {
          const data = await root.$feathers.service('system/term').patch(props.termId, obj)
          emit('update', data)
          start.value = data.confirmation.start.substr(0, 10)
          late.value = data.confirmation.late.substr(0, 10)
          end.value = data.confirmation.end.substr(0, 10)
        }
      }
    })
    const checklistItems = ref([])
    const confirmationWindow = computed(() => {
      if (props.termStart !== '') {
        let dt = new Date(props.termStart)
        dt.setDate(dt.getDate() - 30)
        return dt.toISOString().substr(0, 10)
      }
      return ''
    })

    function updateItem (item, index) {
      if (index in checklistItems.value) {
        checklistItems.value.splice(index, 1, item)
        root.$feathers.service('system/term').patch(props.termId, { ['confirmation.checklist.' + index]: item })
      } else {
        root.$feathers.service('system/term').patch(props.termId, { $push: { ['confirmation.checklist.' + index]: item } })
        checklistItems.value.push(item)
      }
    }

    const termsList = ref([])
    onMounted(() => {
      if (checklistItems.value.length === 0) {
        root.$feathers.service('system/term').find({ query: { 'confirmation.checklist.0': { $exists: true }, $sort: { start: 1, end: 1, label: 1 }, $select: ['_id', 'label'], $limit: 20 } }).then(({ data }) => {
          termsList.value = data
        })
      }
    })
    const copySourceTerm = ref('')
    const copyItems = ref([])
    const selectedItems = ref([])

    watch(copySourceTerm, (_id) => {
      if (_id != null && _id !== '') {
        root.$feathers.service('system/term').get(_id).then(({ confirmation }) => {
          copyItems.value = []
          if ('checklist' in confirmation) {
            confirmation.checklist.forEach((row, index) => {
              copyItems.value.push(row)
              if (row.active) selectedItems.value.push(index)
            })
          }
        })
      }
    })

    function copyFromTerm () {
      const checklist = []
      for (const index of selectedItems.value) {
        const temp = copyItems.value[index]
        delete temp._id
        checklist.push(temp)
      }
      root.$feathers.service('system/term').patch(props.termId, { 'confirmation.checklist': checklist }).then((data) => {
        if ('confirmation' in data && 'checklist' in data.confirmation) {
          checklistItems.value = data.confirmation.checklist
        } else {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Something went wrong copying the confirmation checklist; please contact Technology Services for assistance', timeout: 10000 })
          checklistItems.value = []
        }
      })
    }

    function load () {
      if (props.termId != null && props.termId !== '') {
        root.$feathers.service('system/term').get(props.termId).then(({ confirmation }) => {
          start.value = confirmation.start.substr(0, 10)
          late.value = confirmation.late.substr(0, 10)
          end.value = confirmation.end.substr(0, 10)
          checklistItems.value = ('checklist' in confirmation ? confirmation.checklist : [])
        })
      }
    }

    onMounted(() => {
      load()
    })
    watch(() => props.termId, () => {
      load()
    })

    return {
      start,
      late,
      end,
      confirmationWindow,
      checklistItems,
      updateItem,
      termsList,
      copySourceTerm,
      copyItems,
      selectedItems,
      copyFromTerm
    }
  }
}
</script>
